import { graphql } from "gatsby";
import React from "react";
import Layout from "../../components/layout/Layout";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import * as EcoreparationStyles from "../../styles/ecoreparation.module.css";
import Seo from "../../components/seo";

export default function Ecoreparation({ data }) {
  const { t } = useTranslation();

  return (
    <Layout>
      <Seo description="Les réparations économiques et écologiques. L’ECOMAINTENANCE® privilégie l’économie circulaire avec le recours à des échanges réparation de pièces techniques (pompe d'injection, injecteurs) et en matière de remplacements d'organes de dépollution." />
      <Container fluid className={EcoreparationStyles.Container}>
        <Row>
          <Col xs={12} lg={{ span: 6, offset: 1 }}>
            <h1 className={EcoreparationStyles.Titre1}>
              <Trans i18nKey="ecosysteme.ecoreparation.savoirplus.h.1">
                <sup></sup>
              </Trans>
            </h1>
            <h2 className={EcoreparationStyles.Titre2}>
              {t("ecosysteme.ecoreparation.savoirplus.h.2")}
            </h2>
            <p>
              <Trans i18nKey="ecosysteme.ecoreparation.savoirplus.p.1">
                a<span>b</span>
              </Trans>
            </p>
          </Col>
          <Col
            xs={12}
            lg={{ span: 4, offset: 1 }}
            className={EcoreparationStyles.ColImage}
          >
            <StaticImage
              className={EcoreparationStyles.GatsbyImage}
              src="../../images/ecoreparation.png"
              alt="ecoreparation"
              placeholder="BLURRED"
              formats={["AUTO", "WEBP"]}
              layout="fullWidth"
            />
          </Col>
        </Row>
        <Row className={EcoreparationStyles.Partenaires}>
          <Col xs={12}>
            <h1 className={EcoreparationStyles.Titre1}>
              {t("ecosysteme.ecoreparation.savoirplus.h.3")}
            </h1>
          </Col>
          <Col xs={12} md={6} className={EcoreparationStyles.ColImage}>
            <StaticImage
              className={EcoreparationStyles.GatsbyImage}
              src="../../images/savoirplus/ecoreparation/Bosch_logo.png"
              alt="Bosch logo"
              placeholder="BLURRED"
              formats={["AUTO", "WEBP"]}
              layout="fullWidth"
            />
          </Col>
          <Col xs={12} md={6} className={EcoreparationStyles.ColImage}>
            <StaticImage
              className={EcoreparationStyles.GatsbyImage}
              src="../../images/savoirplus/ecoreparation/bosal-logo.png"
              alt="walker logo"
              placeholder="BLURRED"
              formats={["AUTO", "WEBP"]}
              layout="fullWidth"
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

// export page query
export const query = graphql`
  query Ecoreparation($language: String!) {
    projects: allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { lang: { eq: $language } } }
    ) {
      nodes {
        html
        frontmatter {
          lang
          title
          stack
          slug
          thumb {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        id
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
